import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="set-timezone"
export default class extends Controller {
  connect() {
    // Get the input element with the name "timezone"
    const input = this.element.querySelector("input[name='time_zone']")

    // Set the value of the input element to the Browser timezone
    input.value = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Submit the form
    this.element.requestSubmit()
  }
}
